<template>
  <div :style="{ height: h + 'px' }">
    <div class="login-bg"></div>
    <div class="login">
      <el-header class="login-header" height="90px">
        <div class="login-header-title">
          <el-image
            class="login-header-logo"
            :src="require('../../assets/image/logo_bg.png')"
            fit="cover"
          ></el-image>
          <div>Uummii在线教育数据管理平台</div>
        </div>
      </el-header>
      <div class="login-content">
        <div class="login-module">
          <div class="login-back" @click="goBack">
            <i class="el-icon-arrow-left"></i>
            <div>返回</div>
          </div>
          <div class="login-group">
            <div class="login-title">找回密码</div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              class="login-form"
            >
              <el-form-item class="login-item" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入用户账号"
                ></el-input>
              </el-form-item>
              <el-form-item class="login-item" prop="phone">
                <el-input
                  v-model="ruleForm.phone"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item class="login-item" prop="verifycode">
                <div class="login-form-item">
                  <el-input
                    v-model="ruleForm.verifycode"
                    placeholder="请输入验证码"
                  ></el-input>
                  <el-button
                    v-if="isNum"
                    class="code-btn"
                    type="primary"
                    @click="handCode"
                    >获取验证码</el-button
                  >
                  <el-button
                    v-else
                    class="code-btn"
                    type="primary"
                    :disabled="true"
                    >{{ num }}s</el-button
                  >
                </div>
              </el-form-item>
              <el-form-item class="login-item" prop="newPassword">
                <el-input
                  v-model="ruleForm.newPassword"
                  type="password"
                  placeholder="请输入新密码"
                ></el-input>
              </el-form-item>
              <el-form-item class="login-item" prop="passwordConfirmation">
                <el-input
                  v-model="ruleForm.passwordConfirmation"
                  type="password"
                  placeholder="请输入确认密码"
                ></el-input>
              </el-form-item>
              <el-form-item class="login-item">
                <el-button
                  type="primary"
                  class="login-submit"
                  :loading="loading"
                  @click="submitForm('ruleForm')"
                  >{{ loginTitle }}</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <el-image
            class="login-img"
            :src="require('../../assets/image/loginpic.png')"
            fit="cover"
          ></el-image>
        </div>
        <div class="login-bottom">
          <div>童演童语（北京）教育科技有限公司 | 京ICP备1300570</div>
          <div>技术支持：北京启航视讯科技有限公司</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendMsg, forgotPassword } from "@/api/apiList/admin-api";

export default {
  data() {
    return {
      h: 0,
      ruleForm: {
        name: "",
        phone: "",
        verifycode: "",
        newPassword: "",
        passwordConfirmation: "",
      },
      rules: {
        name: [{ required: true, message: "请输入用户账号", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        verifycode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        passwordConfirmation: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
      isNum: true,
      num: 60,
      loading: false,
      loginTitle: "重置密码",
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
        }
      },
      immediate: true,
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handCode() {
      let obj = {
        name: this.ruleForm.name,
        phone: this.ruleForm.phone,
      };
      sendMsg(obj).then((res) => {
        if (res.result == "200") {
          this.isNum = false;
          let timer = setInterval(() => {
            this.num--;
            if (this.num == 0) {
              clearInterval(timer);
              this.isNum = true;
              this.num = 60;
            }
          }, 1000);
        } else if (res.result == 400201) {
          this.$message.error(res.msg);
        } else if (res.result == 400203) {
          this.$message.error(res.msg);
        } else if (res.result == 200007) {
          this.$message.error(res.msg);
        } else if (res.result == 200006) {
          this.$message.error(res.msg);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.loginTitle = "密码重置中";
          forgotPassword(this.ruleForm).then((res) => {
            if (res.result == "200") {
              this.$cookies.remove("userpassword");
              this.loading = false;
              this.loginTitle = "重置密码";
              this.$alert("密码重置成功，请重新登录", "重置密码", {
                confirmButtonText: "确定",
                callback: () => {
                  this.$router.push("/login");
                },
              });
            } else if (res.result == "200004") {
              this.$message.error(res.msg);
              this.loading = false;
              this.loginTitle = "重置密码";
            } else if (res.result == 200005) {
              this.$message.error(res.msg);
              this.loading = false;
              this.loginTitle = "重置密码";
            } else if (res.result == 200006) {
              this.$message.error(res.msg);
              this.loading = false;
              this.loginTitle = "重置密码";
            } else if (res.result == "400202") {
              this.$message.error(res.msg);
              this.loading = false;
              this.loginTitle = "重置密码";
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.h = document.documentElement.clientHeight;
  },
};
</script>

<style lang="scss" scoped>
.login-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/image/bg_max.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(20px);
  z-index: -1;
}

.login {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  .login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 40px;
    background: #fff;

    .login-header-title {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 600;

      .login-header-logo {
        width: 70px;
        height: 70px;
        margin-right: 20px;
      }
    }
  }

  .login-content {
    width: 60%;
    min-width: 800px;
    margin: 0 auto;
    margin-top: 50px;

    .login-module {
      position: relative;
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 10px;

      .login-back {
        display: flex;
        align-items: center;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 1;
        font-size: 14px;
        color: #409eff;
        cursor: pointer;

        div {
          margin-left: 5px;
        }
      }

      .login-group {
        flex: 1;

        .login-title {
          width: 320px;
          margin: 0 auto;
          font-size: 18px;
          font-weight: 500;
          color: #409eff;
          text-align: center;
        }

        .login-form {
          width: 320px;
          margin: 0 auto;
          margin-top: 10px;

          .login-item {
            margin-bottom: 20px;

            &:last-child {
              margin: 0;
            }

            .login-form-item {
              display: flex;
              align-items: center;

              .code-btn {
                width: 116px;
              }
            }

            .login-submit {
              width: 100%;
            }
          }
        }
      }

      .login-img {
        width: 40%;
        min-width: 320px;
      }
    }

    .login-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-size: 12px;
      color: #333;
    }
  }
}
</style>
